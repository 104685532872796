:local(.inner) {
    max-width: 1000px;
    margin: 0 auto;
}

:local(.section) {

    padding: var(--spacing2x) 0;
    color: #232;
}

:local(.sectionGray) {
    background: #efe;
}

:local(.sectionHero) {
    padding: var(--spacing8x);
    font-size: 48px;
    font-weight: 900;
    letter-spacing: -0.05em;
    text-align: center;
    background: var(--mainColor);
    color: #fff;

    @media (max-width:640px) {
        padding: var(--spacing2x);
        font-size: 24px;

    }
}


:local(.sectionTitle) {
    display: block;
    text-align: center;
    margin-bottom: var(--spacing2x);
    letter-spacing: -0.05em;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

}

:local(.sectionSubtitle) {
    margin: var(--spacing4x) 0;
    letter-spacing: -0.05em;
    font-size: 30px;
    text-align: center;

    @media (max-width:640px) {
        margin: var(--spacing2x) 0;
        font-size: 20px;
        padding: 0 var(--spacing);
    }
}