:local(.plans) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing2x);

    @media (max-width:640px) {
        gap: var(--spacing);
        grid-template-columns: 1fr;
        margin: var(--spacing_half);
    }

}

:local(.plan) {
    border: 1px solid #eee;
    padding: 0;
    border-radius: var(--spacing);
    display: grid;
    grid-template-rows: auto auto 1fr auto;

    &:local(.plus) {
        @media (min-width:640px) {
            margin: -10px;
        }
    }

    :local(.planTitle) {
        background: #111;
        color: #fff;
        letter-spacing: -0.05em;
        margin-top: var(--spacing);
        text-align: center;
        padding: var(--spacing_half);
        font-size: 32px;
        font-weight: 700;

        @media (max-width:640px) {
            font-size: 24px;
        }
    }

    :local(.planPrice) {
        letter-spacing: -0.05em;
        margin-top: var(--spacing2x);
        text-align: center;
        font-size: 54px;
        font-weight: 700;

        @media (max-width:640px) {
            margin-top: var(--spacing);
            font-size: 36px;
        }
    }

    :local(.planDescription) {
        letter-spacing: -0.05em;
        margin-top: var(--spacing_half);
        text-align: center;
        padding: var(--spacing_half) var(--spacing2x);
        font-size: 18px;
        font-weight: 400;

        @media (max-width:640px) {
            margin: 0;
            font-size: 16px;
        }
    }

    :local(.planButton) {
        background: var(--mainColor);
        color: #fff;
        letter-spacing: -0.05em;
        margin: var(--spacing);
        text-align: center;
        border-radius: 200px;
        padding: var(--spacing_half);
        font-size: 24px;
        font-weight: 700;

        @media (max-width:640px) {
            margin: var(--spacing_half);
            font-size: 18px;
        }
    }
}

:local(.benefits) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width:640px) {
        grid-template-columns: 1fr;
    }


    :local(.benefit) {
        padding: var(--spacing2x);
        text-align: center;
        display: grid;
        align-items: flex-start;
        gap: var(--spacing);

        @media (max-width:640px) {
            padding: var(--spacing);
            margin-top: var(--spacing);
            text-align: left;
            grid-template-columns: auto 1fr;
        }

        :local(.title) {
            font-size: 24px;
            margin-top: var(--spacing);
            padding: var(--spacing_half) 0;
            font-weight: 700;

            @media (max-width:640px) {
                margin-top: 0;
                padding: 0;
                font-size: 20px;
            }
        }

        :local(.description) {
            font-size: 18px;

            @media (max-width:640px) {
                margin-top: 0;
                font-size: 16px;
            }
        }

        :local(.icon img) {
            width: 100px;

            @media (max-width:640px) {
                width: 75px;
            }
        }

    }
}

:local(.howItWorks) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing);

    @media (max-width:640px) {
        gap: var(--spacing);
        grid-template-columns: 1fr;
        margin: var(--spacing2x) var(--spacing2x);
    }


    :local(.step) {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: var(--spacing);
        align-items: flex-start;
        justify-content: flex-start;

        :local(.number) {
            background: #111;
            height: 32px;
            width: 32px;
            font-weight: 700;
            line-height: 30px;
            color: #fff;
            border-radius: 100px;
            text-align: center;

        }
    }

}


:local(.contact) {
    text-align: center;
}

:local(.contactButton) {
    background: var(--mainColor);
    color: #fff;
    display: inline-block;
    letter-spacing: -0.05em;
    margin: var(--spacing) auto;
    text-align: center;
    border-radius: 200px;
    padding: var(--spacing) var(--spacing2x);
    font-size: 32px;
    font-weight: 700;
    vertical-align: middle;
    line-height: 32px;

    svg {
        margin-right: 10px;
    }

    @media (max-width:640px) {
        margin: var(--spacing_half);
        font-size: 18px;
    }
}